import { useEffect } from 'react';
import { Routes, Route, Outlet, useLocation } from 'react-router';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { useFeatureFlagsContext } from '@src/context/FeatureFlagsContext';
import RoutingWrapper from '@src/components/RoutingWrapper';
import LoggedOutRoute from '@src/components/LoggedOut';
import Register from '@src/components/Register';
import ReviewDocs from '@src/components/ReviewDocs';
import flags from '@src/constants/feature-flags';
import config from '@src/config';

if (config.datadogApplicationId && config.datadogClientToken) {
  datadogRum.init({
    applicationId: config.datadogApplicationId,
    clientToken: config.datadogClientToken,
    site: config.datadogRumSite,
    service: config.datadogService,
    env: config.environment,
    version: config.commitSha,
    sampleRate: 25,
    trackInteractions: true,
  });

  datadogLogs.init({
    clientToken: config.datadogClientToken,
    site: config.datadogRumSite,
    service: config.datadogService,
    env: config.environment,
    version: config.commitSha,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
}

export const Redirect = (): JSX.Element => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const newPath = pathname.startsWith('/t/') ? pathname.substring(3) : pathname.substring(1);
    window.location.href = `${process.env.REACT_APP_API_HOST as string}${newPath}${search}`;
  }, [pathname]);

  return <Outlet />;
};

export const BusinessRouter = (): JSX.Element => {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext();

  const isBusinessRegisterEnabled = isFeatureFlagEnabled(flags.BUSINESS_REGISTER_ENABLED);
  const isBusinessLoginEnabled = isFeatureFlagEnabled(flags.BUSINESS_LOGIN_ENABLED);
  const isBusinessReviewEnabled = isFeatureFlagEnabled(flags.BUSINESS_REVIEW_ENABLED);
  const isBusinessBasicInformationEnabled = isFeatureFlagEnabled(flags.BUSINESS_BASIC_INFORMATION_ENABLED);
  const isBusinessUploadDocumentsEnabled = isFeatureFlagEnabled(flags.BUSINESS_UPLOAD_DOCUMENTS_ENABLED);
  const isBusinessSupportEnabled = isFeatureFlagEnabled(flags.BUSINESS_SUPPORT_ENABLED);
  const isBusinessTermsEnabled = isFeatureFlagEnabled(flags.BUSINESS_TERMS_ENABLED);

  return (
    <Routes>
      <Route path="t" element={<Outlet />}>
        <Route path="business" element={<Outlet />}>
          <Route path="upgrade" element={<RoutingWrapper />}>
            {isBusinessReviewEnabled && <Route path="review" element={<ReviewDocs />} />}
            {isBusinessBasicInformationEnabled && (
              <Route path="basic_information" element={<div>Basic Information</div>} />
            )}
            {isBusinessUploadDocumentsEnabled && (
              <Route path="upload_documents" element={<div>Upload Documents</div>} />
            )}
            {isBusinessSupportEnabled && <Route path="support" element={<div>Support</div>} />}
            {isBusinessTermsEnabled && <Route path="terms" element={<div>Terms</div>} />}
            <Route path="" element={<Redirect />} />
          </Route>

          <Route path="" element={<LoggedOutRoute />}>
            {isBusinessRegisterEnabled && <Route path="register" element={<Register />} />}
            {isBusinessLoginEnabled && <Route path="login" element={<div>Login</div>} />}
            <Route path="" element={<Redirect />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};

export default BusinessRouter;
