import url from 'url';
import type { BigNumber } from 'bignumber.js';

import BitsoNumber from '@src/library/BitsoNumber';
import { clients as bitsoClient } from '@src/library/analytics';

const toCamelCase = (str: string) => str.replace(/\s(.)/g, $1 => $1.toUpperCase()).replace(/\s/g, '').replace(/^(.)/, $1 => $1.toLowerCase()); // prettier-ignore

const getCurrencySymbol = (coin: string): string => {
  switch (coin.toLowerCase()) {
    case 'mxn':
    case 'usd':
      return '$';
    case 'xau':
    case 'xrp':
    case 'btc':
    case 'eth':
    case 'bch':
    case 'ltc':
    case 'mana':
    case 'gnt':
    case 'bat':
    case 'cad':
    case 'tusd':
    default:
      return '';
  }
};

const getPrecision = (coin: string): number => {
  switch (coin.toLowerCase()) {
    case 'xau':
    case 'xrp':
      return 6;

    case 'btc':
    case 'eth':
    case 'bch':
    case 'ltc':
    case 'mana':
    case 'gnt':
    case 'bat':
      return 8;

    case 'mxn':
    case 'cad':
    case 'usd':
    case 'tusd':
    case 'ars':
    case 'dai':
    default:
      return 2;
  }
};

const safeToFormat = (value: string | number | BigNumber, precision: number): string => {
  const bValue = BitsoNumber(value);
  const valueFormatted = bValue.isFinite() ? bValue.toFormat(precision) : '-';
  return valueFormatted;
};

const format = (
  value: number | BigNumber | string,
  coin = '',
  showCurrencySymbol = false,
  showCurrency = false,
  precision?: number
): string => {
  const currencySymbol = showCurrencySymbol ? getCurrencySymbol(coin || '') : '';
  const coinPrecision = precision || getPrecision(coin);
  const valueFormatted = safeToFormat(value, coinPrecision);
  const currencyFormatted = showCurrency && coin ? ` ${coin.toUpperCase()}` : '';
  return `${currencySymbol}${valueFormatted}${currencyFormatted}`;
};

const getProduct = () => 'bitbank';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSegmentClient = (product?: string, clients?: any) => {
  const segmentClients = clients || bitsoClient;
  const trackedClient = segmentClients.BITBANK_WALLET;

  return trackedClient;
};

const redirectTo = (redirectQueryParam?: string, baseLocation?: Location): string => {
  const location = baseLocation || window.location;

  const defaultUrl = '/wallet';
  const parsedUrl = url.parse(redirectQueryParam || defaultUrl);
  const uri = new URL((parsedUrl.path === '/' ? defaultUrl : parsedUrl.path) || '', location.origin);

  if (uri.host !== location.host) {
    return `${location.origin}${defaultUrl}`;
  }

  return uri.href;
};

export { toCamelCase, format, getSegmentClient, getProduct, redirectTo };
