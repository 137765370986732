import base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import cookie from 'js-cookie';

import { getViewport } from '@src/shared/styles/Breakpoints';

import initiateAvo from './avoInspector';

const inspector = initiateAvo();

/* SEGMENT EVENTS */
export const events = {
  ACCEPTED_COOKIES: 'Accepted Cookies',
  AGREED_NEW_TERMS: 'Agreed New Terms',
  APPCUES_REGISTER_FROM: 'AppCues Register From',
  APPLIED_GRAPH_PREFERENCES: 'Applied Graph Preferences',
  APPLIED_STUDY_TYPE: 'Applied Study Type',
  AUTHORIZE_WIRE_ACCOUNT: 'Authorized a Wire Account',
  CANCELED_A_TRADE_ORDER: 'Canceled a Trade Order',
  CANCELED_ALL_ORDERS: 'Canceled all Orders',
  CHANGED_CHART_TIMESPAN: 'Changed Chart TimeSpan',
  CHANGED_EXECUTION_TYPE: 'Changed Execution Type',
  CHANGED_GRAPH: 'Changed Graph',
  CHANGED_ORDER_BOOK: 'Changed Order Book',
  CHANGED_ORDER_TYPE: 'Changed Order Type',
  CHANGED_PIX_KEY_TYPE: 'Changed Pix KeyType',
  CHANGED_THEME: 'Changed Theme',
  CLICKED_BALANCE_BAR: 'Clicked Balance Bar',
  CLICKED_GO_TO_ALPHA_PRO: 'Clicked go to Alpha Pro',
  CLICKED_ON_AN_ORDER_BOOK_ORDER: 'Clicked on an Order Book Order',
  CLICKED_ON_AVAILABLE_BALANCE: 'Clicked on Available Balance',
  CLICKED_ON_HISTORY: 'Clicked on History',
  CLICKED_ON_LAST_TRADES_ORDERS: 'Clicked on Last Trades Orders',
  CLICKED_ON_LOGOUT: 'Clicked on Log Out ',
  CLICKED_ON_ORDER_BOOK_TAB: 'Clicked on Order Book Tab',
  CLICKED_ON_ORDERS_TAB: 'Clicked on Orders Tab',
  CLICKED_ON_PROFILE: 'Clicked on Profile',
  CLICKED_ON_SLIPPAGE_WARNING: 'Clicked on Slippage Warning',
  CLICKED_ON_TOOLTIP: 'Clicked on Tooltip',
  CLICKED_ON_TRADES_TAB: 'Clicked on Trades Tab',
  CLICKED_ON_TV_TAB: 'Clicked on Trading View Tab',
  CLICKED_ON_WALLET: 'Clicked on Wallet',
  CLICKED_READ_MORE_ABOUT_COOKIES: 'Clicked Read More About Cookies',
  CLICKED_TO_DOWNLOAD_ALPHA_APP: 'Clicked to download Alpha App',
  CLICKED_TRADE_NOW_BUTTON: 'Clicked Trade Now button',
  CLICKED_UPLOAD_DOCUMENTS: 'Clicked in Upload Documents',
  CLOSE_2FA_MODAL: 'Closed Modal',
  CLOSE_NVIO_TERMS_MODAL: 'Closed View',
  CLOSED_ALPHA_APP_BANNER: 'Closed Alpha App Banner',
  CLOSED_BITSO_TRANFER_ONBOARDING_MODAL: 'Closed Modal',
  CLOSED_MODAL: 'Closed Modal',
  CLOSED_OTP_CONFIRMATION: 'Closed Modal',
  CONFIRMED_NAME: 'Confirmed Name',
  COMPLETED_2FA: 'Completed 2FA',
  COMPLETED_BLOCK_ACCOUNT: 'Completed block account',
  COMPLETED_CHANGE_EMAIL: 'Completed Change Email',
  COMPLETED_CHANGE_PASSWORD: 'Completed Change Password',
  COMPLETED_DISABLE_WITHDRAWALS: 'Completed disable withdrawals',
  COMPLETED_EMAIL_ENCRYPTION: 'Completed Email Encryption',
  COMPLETED_ENABLE_WITHDRAWALS: 'Completed enable withdrawals',
  COMPLETED_INFO_VALIDATION_STEP: 'Completed Info Validation step',
  COMPLETED_LOGIN: 'Completed Login',
  COMPLETED_OTP: 'Completed OTP',
  COMPLETED_PHONE_VERIFICATION: 'Completed Phone Verification',
  COMPLETED_REMOVE_EMAIL_ENCRYPTION: 'Completed Remove Email Encryption',
  COMPLETED_RESET_2FA: 'Completed reset 2FA',
  COMPLETED_RESET_PIN: 'Completed Reset PIN',
  COMPLETED_VALID_BUSINESS_INFO: 'Completed Valid Business Info',
  CONFIRMED_AMOUNT: 'Confirmed Amount',
  CONFIRMED_DEPOSIT_AMOUNT: 'Confirmed Deposit Amount',
  CONFIRMED_SCAM_MODAL: 'Confirmed Scam Modal',
  COPIED_ACCOUNT_INFORMATION_TO_CLIPBOARD: 'Copied Account Information to Clipboard',
  COPIED_INFORMATION: 'Copied Information',
  CREATED_EXCHANGE_NAME: 'Created Exchange Name',
  DELETED_BENEFICIARY: 'Deleted Beneficiary',
  DELETED_FAVORITE_MARKET: 'Deleted Favorite Market',
  DEVICE_AUTHORIZED: 'Device Authorized',
  DISABLED_2FA: 'Disabled 2FA',
  DISABLED_ORDER_CONFIRMATION: 'Disabled Order Confirmation',
  DOWNLOADED_A_CHART: 'Downloaded a Chart',
  ENABLED_2FA: 'Enabled 2FA',
  ENROLLED_IN_PRODUCT: 'Enrolled in Product',
  EXECUTED_A_CONVERSION: 'Executed a Conversion',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  EXPIRED_OTP: 'Expired OTP',
  FILLED_AMOUNT_ORDER_FORM: 'Filled Amount Order Form',
  FILLED_PRICE_ORDER_FORM: 'Filled Price Order Form',
  FILLED_TOTAL_ORDER_FORM: 'Filled Total Order Form',
  GENERATE_MANUALLY_ADDRESS: 'Requested Deposit Address',
  GOT_AN_ERROR: 'Got an Error',
  INSERTED_AMOUNT: 'Inserted Amount',
  INSERTED_DESTINATION_ADDRESS: 'Inserted Destination Address',
  INSERTED_PIX_KEY: 'Inserted Pix Key',
  LOADED_A_PAGE: 'Loaded a Page',
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',
  OPEN_2FA_MODAL: 'Opened Modal',
  OPEN_OTP_CONFIRMATION: 'Opened Modal',
  OPENED_BITSO_TRANFER_ONBOARDING_MODAL: 'Opened Modal',
  OPENED_MODAL: 'Opened Modal',
  OPENED_REFERRAL_PAGE: 'Opened View',
  OPENED_SAVED_ACCOUNTS: 'Opened Saved Accounts',
  OPENED_TRANSACTION_DETAIL: 'Opened Transaction Detail',
  OPENED_VIEW: 'Opened View',
  PREVIEWED_MANUAL_WIRE_WITHDRAWAL: 'Previewed Manual Wire Withdrawal',
  PREVIEWED_ORDER: 'Previewed Order',
  PREVIEWED_WITHDRAWAL: 'Previewed Withdrawal',
  PRESSED_BACKSPACE_KEY: 'Tapped on Delete Button',
  PROMPTED_TO_ACCEPT_COOKIES: 'Prompted to Accept Cookies',
  PROMPTED_TO_LEVEL_UP: 'Prompted to Level Up',
  REDIRECTED_TO_SITE: 'Redirected to Site',
  REMOVED_GRAPH_PREFERENCES: 'Removed Graph Preferences',
  REMOVED_STUDY: 'Removed Study',
  REPORT_A_HIT: 'Report A Hit',
  REQUEST_ADD_BENEFICIARY: 'Requested Beneficiary creation',
  REQUEST_BLOCK_ACCOUNT: 'Requested to block account',
  REQUESTED_A_QUOTE: 'Requested a Quote',
  REQUESTED_A_WITHDRAWAL: 'Requested a Withdrawal',
  REQUESTED_EMAIL_ENCRYPTION: 'Requested Email Encryption',
  REQUESTED_LOGIN: 'Requested Login',
  REQUESTED_OTP_CONFIRMATION: 'Requested OTP',
  REQUESTED_REMOVE_EMAIL_ENCRYPTION: 'Requested Remove Email Encryption',
  REQUESTED_RESET_2FA_WITH_EMAIL_FORM: 'Request reset 2FA with email form',
  REQUESTED_RESET_2FA_WITH_EMERGENCY_CODE: 'Request reset 2FA with emergency code',
  REQUESTED_RESET_PIN: 'Requested Reset PIN',
  REQUESTED_TO_CHANGE_EMAIL: 'Requested to Change Email',
  REQUESTED_TO_CHANGE_PASSWORD: 'Requested to Change Password',
  REQUESTED_TO_DISABLE_2FA: 'Requested  to Disable 2FA',
  REQUESTED_TO_ENABLE_2FA: 'Requested  to Enable 2FA',
  RETRIED_PHONE_VERIFICATION: 'Retried Phone Verification',
  SAVED_A_CONTACT: 'Saved a Contact',
  SAW_SLIPPAGE_WARNING: 'Saw Slippage Warning',
  SAW_SUCCESS_SCREEN: 'Saw Success Screen',
  SEARCHED_IN_BALANCE: 'Searched in Balance',
  SEARCHED_IN_MARKET_SELECTOR: 'Searched in Market Selector',
  SEARCHED_IN_WALLET_CURRENCIES: 'Searched in Wallet Search Bar',
  SELECTED_ADDRESS_TYPE: 'Selected Address Type',
  SELECTED_ADDRESS_OWNER_TYPE: 'Selected Address Owner Type',
  SELECTED_CONVERSION_PAIR: 'Selected Conversion Pair',
  SELECTED_EXCHANGE: 'Selected Exchange',
  SELECTED_FAVORITE_MARKET: 'Selected Favorite Market',
  SELECTED_WITHDRAWAL_METHOD: 'Selected Withdrawal Method',
  SELECTED_AN_ACCOUNT: 'Selected an Account',
  SENT_ALL_DOCUMENTATION: 'Sent All Documentation',
  SHARED_INFORMATION: 'Shared Information',
  SHOWED_DEVICE_AUTH_SCREEN: 'Showed Device Auth Screen',
  SHOWED_SECURITY_ALERT: 'Showed Security Level Indicator',
  SIGNED_UP: 'Signed Up',
  SKIPPED_VIEW: 'Skipped View',
  STARTED_AUTHORIZATION_OF_ACCOUNT: 'Started Authorization of Account',
  STARTED_EXCHANGE_CREATION: 'Started Exchange Creation',
  STARTED_ID_VERIFICATION: 'Started ID Verification',
  STARTED_INVITATION_FLOW: 'Started Invitation Flow',
  STARTED_PHONE_VERIFICATION: 'Started Phone Verification',
  STARTED_SAVE_CONTACT: 'Started Save a Contact',
  SUBMITTED_2FA: 'Submitted 2FA',
  SUBMITTED_BANK_DETAILS: 'Submitted Bank Details',
  SUBMITTED_BENEFICIARY: 'Completed Beneficiary creation',
  SUBMITTED_ID_VERIFICATION: 'Submitted ID Verification',
  SUBMITTED_ACCOUNT_TYPE: 'Submitted Account Type',
  SUBMITTED_OTP: 'Submitted OTP',
  SWITCHED_CURRENCY_DENOMINATION: 'Switched Currency Denomination',
  SWITCHED_CURRENCY_IN_MARKET: 'Switched Currency in Market',
  SWITCHED_TO_BUYING: 'Switched to Buying',
  SWITCHED_TO_PRODUCT: 'Switched to Product',
  SWITCHED_TO_SELLING: 'Switched to Selling',
  TAPPED_CONTACT_US: 'Tapped Contact Us',
  TAPPED_CONVERT_BUTTON: 'Tapped Convert Button',
  TAPPED_CURRENCY: 'Tapped Currency',
  TAPPED_DEPOSIT_BUTTON: 'Tapped Deposit Button',
  TAPPED_FORGOT_MY_PASSWORD: 'Tapped Forgot my password',
  TAPPED_FUND_IN_FIAT: 'Tapped Fund in Fiat',
  TAPPED_FUND_METHOD: 'Selected Deposit Method',
  TAPPED_INCREASE_LIMIT_BUTTON: 'Tapped Increase Limit button',
  TAPPED_LEARN_MORE: 'Tapped Learn More',
  TAPPED_LOGIN_BUTTON: 'Tapped Login button',
  TAPPED_MAX_AVAILABLE_AMOUNT: 'Tapped Max Available Amount',
  TAPPED_ON_AVAILABLE_TO_SEND: 'Tapped on Available to Send',
  TAPPED_ON_MY_TRADES: 'Tapped on My Trades',
  TAPPED_SEND_BUTTON: 'Tapped Send Button',
  TAPPED_CONTINUE_BUTTON: 'Tapped Continue',
  TAPPED_MAYBE_LATER: 'Tapped Maybe Later',
  TOGGLE_TO_BUY_IN_ORDER_BOOK: 'Toggle to Buy in Order Book',
  TOGGLE_TO_MARKET_IN_TRADES_TAB: 'Toggle to Market in Trades Tab',
  TOGGLE_TO_MY_TRADES_IN_TRADES_TAB: 'Toggle to My Trades in Trades Tab',
  TOGGLE_TO_SELL_IN_ORDER_BOOK: 'Toggle to Sell in Order Book',
  TOGGLED_GRAPH_EXPANSION: 'Toggled Graph Expansion',
  TOGGLED_HIDE_ZERO_BALANCES: 'Toggled Hide Zero Balances',
  TOGGLED_INPUT_CURRENCY: 'Toggled Input Currency',
  TOGGLED_MORE_OPTIONS: 'Toggled More Options',
  TOGGLED_ONE_CLICK_ORDER: 'Toggled One Click Order',
  TOGGLED_REAL_TIME_CHART: 'Toggled Real Time Chart',
  TOGGLED_TO_LIMIT_ORDERS: 'Toggled to Limit Orders',
  TOGGLED_TO_STOP_ORDERS: 'Toggled to Stop Orders',
  TOGGLED_VIEW_ACCORDION: 'Toggled View',
  TOGGLED_ACTIVITY_NOTIFICATION: 'Toggled Activity Notification',
  TOGGLED_INFORMATIVE_NOTIFICATION: 'Toggled Informative Notification',
  UPLOADED_A_DOCUMENT: 'Uploaded A Document',
  VERIFICATION_FAILED_2FA: 'Verification Failed 2FA',
  VERIFICATION_FAILED_OTP: 'Verification Failed OTP',
  VIEWED_ALPHA_CLASSIC_EXPERIMENT: 'Viewed Alpha Classic Experiment',
  VIEWED_TRADINGVIEW_EXPERIMENT: 'Viewed TradingView Experiment',
  ZOOMED_ON_CHART: 'Zoomed on Chart',
  ATTRIBUTED_USER_TRAFFIC: 'Attributed User Traffic',
} as const;

/* SEGMENT CLIENTS */
export const clients = {
  ALPHA: 'alpha-web',
  BITBANK_WALLET: 'bitbank-wallet-web',
  BITBANK_WALLET_BUSINESS: 'bitbank-wallet-web-business-only',
  NVIO: 'nvio-web',
  SHIFT: 'shift-web',
} as const;

export const methods = { ALPHA: 'alpha', ALPHA_CLASSIC: 'alpha-classic' } as const;

export const addressTypes = {
  EXCHANGE: 'exchange',
  WALLET: 'wallet',
} as const;

export const addressOwnershipType = {
  SELFOWNED: 'selfowned',
  NATURAL_THIRD_PARTY: 'natural-third-party',
  LEGAL_THIRD_PARTY: 'legal-third-party',
} as const;

export const paymentTypes = Object.freeze({
  SAVED_CONTACT: 'saved-contact',
  NON_SAVED_CONTACT: 'non-saved-contact',
} as const);

export const origins = Object.freeze({
  WITHDRAWAL: 'Withdrawal',
  MARKET_PAGE: 'market-page',
} as const);

export const withdrawalStatuses = {
  ON_HOLD: 'funds-on-hold',
  NO_ON_HOLD: 'no-funds-on-hold',
} as const;

export type Event = BitsoGlobals.Values<typeof events>;
export type WithdrawalStatus = BitsoGlobals.Values<typeof withdrawalStatuses>;
export type PaymentType = BitsoGlobals.Values<typeof paymentTypes>;
export type Origins = BitsoGlobals.Values<typeof origins>;
export type Method = BitsoGlobals.Values<typeof methods>;
export type TrackedClient = BitsoGlobals.Values<typeof clients>;
export type AddressTypes = BitsoGlobals.Values<typeof addressTypes>;
export type AddressOwnershipTypes = BitsoGlobals.Values<typeof addressOwnershipType>;

interface IdentifyParams {
  UsesWeb?: boolean;
  userId: string;
  [key: string]: unknown;
}

export interface AnalyticCommonAttributes {
  identify: (userId: string, params: IdentifyParams) => void;
  page: ({ Viewport: string }) => void;
  track: (event: Event, payload: TrackPayload) => void;
  reset: () => void;
}

export interface AnalyticCommonAttributes {
  identify: (userId: string, params: IdentifyParams) => void;
  page: ({ Viewport: string }) => void;
  track: (event: Event, payload: TrackPayload) => void;
  reset: () => void;
}

export interface TrackProperties {
  Address: string;
  AddressType: AddressTypes;
  AddressOwnershipType: AddressOwnershipTypes;
  Amount: number;
  Balance: number;
  CreatedExchange: string;
  Currency: string;
  CurrencyPair: string;
  Direction: string;
  ExecutionType: string;
  ExpandedView: boolean;
  Graph: string;
  HidingZeroBalances: boolean;
  KeyType: string;
  LastDenomination: string;
  LastOrderBook: string;
  LastTimeSpan: string;
  Method: Method | string;
  MoreOptions: boolean;
  Network: string;
  NewDenomination: string;
  NewOrderBook: string;
  NewOrderType: string;
  NewSetting: string;
  NewTimeSpan: string;
  NumberOrders: number;
  OldSetting: string;
  OrderAmount: number | null;
  OrderPrice: number | null;
  OrderType: string;
  OrderValue: number | null;
  Origin: Origins | string;
  PaymentType: PaymentType;
  Percentage: string;
  Price: number;
  Placement: string;
  SearchTerm: string;
  Setting: string;
  ShowingBalance: boolean;
  Side: string;
  Step: string;
  TotalValue: number;
  Type: string;
  Viewport: string;
  ViewType: string;
  WithdrawalStatus: WithdrawalStatus;
  [key: string]: unknown;
}

export interface ClevertapUserData {
  Email: string;
  Identity: string;
  Name?: string;
  Country?: keyof BitsoGlobals.Countries;
  Language?: string;
  Phone?: string;
  'Account Type'?: 'BUSINESS' | 'CONSUMER';
  'First Name'?: string;
  'Last Name'?: string;
  'MSG-email'?: boolean;
  'MSG-push'?: boolean;
  'MSG-sms'?: boolean;
  'MSG-whatsapp'?: boolean;
  DOB: Date;
  Gender: string;
}

/**
 * @deprecated Stop using unknown and instead list all properties in TrackProperties
 */
type DeprecatedType = {
  [key: string]: unknown;
};

export interface TrackPayload extends Partial<TrackProperties> {
  Client: TrackedClient;
}

/* TRACKER FUNCTIONS */
const tracker = {
  page: (): void => {
    const Viewport = getViewport();

    if (window.analytics) window.analytics.page({ Viewport });
    if (window.Appcues) window.Appcues.page({ Viewport });
    if (window._sift) {
      const csrf = cookie.get('csrf') || '';
      const sessionId = base64.stringify(sha256(csrf));
      window._sift.push(['_setSessionId', sessionId]);
      window._sift.push(['_trackPageview']);
    }
  },
  track: (event: Event, client: TrackedClient, properties: Partial<TrackProperties> & DeprecatedType = {}): void => {
    const Viewport = getViewport();

    const { analytics, DD_RUM } = window;
    const payload = { Client: client, Viewport, ...properties };

    inspector?.trackSchemaFromEvent(event, payload);

    if (analytics) analytics.track(event, payload);
    if (DD_RUM) DD_RUM.addAction(event, payload);
  },
  appcues: (event: Event, client: TrackedClient, properties: { [key: string]: unknown } = {}): void => {
    const { Appcues } = window;
    const payload = { Client: client, ...properties };
    if (Appcues) Appcues.track(event, payload);
  },
  identify: (userId: string, traits?: { [key: string]: unknown }): void => {
    if (window.analytics) window.analytics.identify(userId, { UsesWeb: true, userId, ...traits });
    if (window.Appcues) window.Appcues.identify(userId, { userId, ...traits });
    if (window._sift) window._sift.push(['_setUserId', userId]);
  },
  clevertapIdentify: (userId: string, traits: Omit<ClevertapUserData, 'Identity'>): void => {
    if (window.clevertap) {
      window.clevertap.onUserLogin.push({
        Site: {
          Identity: userId,
          ...traits,
        },
      });
    }
  },
  reset: (): void => {
    if (window.analytics) window.analytics.reset();
    if (window.Appcues) window.Appcues.reset();
  },
};

export default tracker;
