import { Outlet } from 'react-router';
import qs from 'query-string';

import { useAccountStatusContext } from '@src/context/AccountStatusContext';
import { redirectTo } from '@src/library/helpers';

const LoggedOutRoute: BitsoGlobals.Component = () => {
  const {
    accountStatus: { data },
  } = useAccountStatusContext();

  if (!data?.client_id) {
    return <Outlet />;
  }

  const { redirect: redirectQueryParam } = qs.parse(window.location.search);

  window.location.href = redirectTo((redirectQueryParam as string) || '');

  return <Outlet />;
};

export default LoggedOutRoute;
