/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, ReactNode } from 'react';
import useFeatureFlags, { UseFeatureFlagsType, FeatureFlags } from './hooks/useFeatureFlags';

const defaultInitialContext: UseFeatureFlagsType = {
  loaded: false,
  getExperimentVariant: (_: string) => null,
  isFeatureFlagEnabled: (_: FeatureFlags) => false,
  setCustomerUserId: (_: number) => undefined,
};

const FeatureFlagsContext = createContext<UseFeatureFlagsType>(defaultInitialContext);

type Params = { children?: ReactNode };

export const FeatureFlagsContextProvider = ({ children }: Params): JSX.Element => {
  const values = useFeatureFlags();

  return <FeatureFlagsContext.Provider value={values}>{values.loaded ? children : <></>}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlagsContext = (): UseFeatureFlagsType => useContext(FeatureFlagsContext);

export default FeatureFlagsContextProvider;
