/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import cookie from 'js-cookie';

import conf from '@src/config';
import { getViewport } from '@src/shared/styles/Breakpoints';

export const apiConfig = { baseURL: conf.apiV3Path, headers: { 'content-type': 'application/json' } };

const PRODUCT = process.env.REACT_APP_PRODUCT || process.env.NEXT_PUBLIC_PRODUCT;
const VERSION = process.env.REACT_APP_VERSION || process.env.NEXT_PUBLIC_VERSION;

const axiosInstance = axios.create(apiConfig);

export const getConfig = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const csrfCookie = cookie.get('csrf');
  const defaultLanguage = 'en-us';
  const localStorageLanguage =
    typeof localStorage !== 'undefined' && localStorage != null ? localStorage.getItem('settings:language') : null;
  const navigatorLanguage =
    typeof navigator !== 'undefined' && navigator != null
      ? (navigator.languages && navigator.languages[0]) || navigator.language
      : null;

  const languageToUse = localStorageLanguage || navigatorLanguage || defaultLanguage;
  const [language] = languageToUse.toLowerCase().split(/[_-]+/);

  config.headers.common.Authorization = `Bitso-CSRF-TOKEN ${csrfCookie}`;
  config.headers.common['Accept-Language'] = language;
  config.headers['bts-client-version'] = VERSION || 'local';
  config.headers['bts-client-platform'] = 'web';

  const isWindowAvailable = typeof window !== 'undefined' && window != null;
  const fromAlpha = isWindowAvailable ? window.location.pathname.startsWith('/alpha') : null;
  const fromAlphaClassic = isWindowAvailable ? window.location.pathname.startsWith('/classic') : null;
  const fromAutoSell = isWindowAvailable ? window.location.pathname.includes('autosell') : null;
  const fromWallet = isWindowAvailable ? window.location.pathname.includes('wallet') : null;
  const fromShift = isWindowAvailable ? window.location.pathname.startsWith('/shift') : null;

  if (fromAlpha || fromAlphaClassic) {
    config.headers['bts-client-name'] = 'alpha-web';
    config.headers['bts-client-method'] = fromAlpha ? 'alpha' : 'alpha-classic';
    config.headers['bts-evt-viewport'] = getViewport();
  } else if (PRODUCT === 'nvio') {
    config.headers['bts-client-name'] = 'nvio-web';
    config.headers.common['Accept-Language'] = 'es';
  } else if (fromShift) {
    config.headers['bts-client-name'] = 'shift-web';
    config.headers['bts-client-method'] = 'conversion';
  } else if (fromAutoSell) {
    config.headers['bts-client-method'] = 'autosell';
    config.headers['bts-client-name'] = 'bitbank-wallet-web';
  } else if (fromWallet) {
    config.headers['bts-client-method'] = 'conversion';
    config.headers['bts-client-name'] = 'bitbank-wallet-web';
  } else {
    config.headers['bts-client-name'] = 'bitbank-wallet-web';
  }

  return config;
};

axiosInstance.interceptors.request.use(getConfig);

export interface ResponseType<DataType = Record<string, unknown>> {
  success: boolean;
  payload: DataType;
}

export type ResponseModel<DataType = Record<string, unknown>> = AxiosResponse<ResponseType<DataType>>;

export const API_DATE_FORMAT = 'MM/DD/YYYY';
export const DATEFNS_API_DATE_FORMAT = 'MM/dd/yyyy';

export default axiosInstance;
