import * as Inspector from 'node-avo-inspector';
import type { AvoInspector } from 'node-avo-inspector';

const initiateAvo = (): AvoInspector => {
  let avoInspector;

  if (process.env.REACT_APP_AVO_INSPECTOR_KEY) {
    if (Inspector) {
      avoInspector = new Inspector.AvoInspector({
        apiKey: process.env.REACT_APP_AVO_INSPECTOR_KEY,
        env:
          process.env.REACT_APP_PREVIEW_ENV_ENABLE && process.env.REACT_APP_PREVIEW_ENV_ENABLE === 'true'
            ? 'dev'
            : 'prod',
        version: '1.0.0',
        appName: 'React Web',
      });
    }
  }

  return avoInspector;
};

export default initiateAvo;
