// TODO: We need to refactor all the implementation of BitsoNumber
import BigNumber from 'bignumber.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const BitsoNumber = (number: any) => {
  BigNumber.config({ ROUNDING_MODE: 1 });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return BigNumber(number);
};

export default BitsoNumber;
